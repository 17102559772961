.Image {
  max-width: 100%;
}
.Image_contain {
  -o-object-fit: contain;
     object-fit: contain;
}
.Image_cover {
  -o-object-fit: cover;
     object-fit: cover;
}
.Image_full-width {
  width: 100%;
}
.Image_as-background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}
.Image_rounded {
  border-radius: 5px;
}
.Image__error {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NSIgaGVpZ2h0PSI0NSIgdmlld0JveD0iMCAwIDQ1IDQ1Ij4KICA8Zz4KICAgIDxwYXRoIGQ9Ik0yOC41LDEySDQuNUE0LjUxLDQuNTEsMCwwLDAsMCwxNi41djI0QTQuNTEsNC41MSwwLDAsMCw0LjUsNDVoMjRBNC41MSw0LjUxLDAsMCwwLDMzLDQwLjV2LTI0QTQuNTEsNC41MSwwLDAsMCwyOC41LDEyWk04LDE2YTMsMywwLDEsMS0zLDNBMywzLDAsMCwxLDgsMTZaTTI4Ljc3LDQxSDUuMjNhMS4yNCwxLjI0LDAsMCwxLTEuMDYtMS44N0wxMC40LDI4Ljc2YTEuMjQsMS4yNCwwLDAsMSwyLjEyLDBMMTMuODMsMzFsNC40OS03LjQ3YTEuMjQsMS4yNCwwLDAsMSwyLjEyLDBsOS4zOSwxNS42NEExLjI0LDEuMjQsMCwwLDEsMjguNzcsNDFaIiBmaWxsPSIjZDJkMmQyIi8+CiAgICA8cGF0aCBkPSJNMTIsNC41djRoM3YtNEExLjUsMS41LDAsMCwxLDE2LjUsM2gyNEExLjUsMS41LDAsMCwxLDQyLDQuNXYyNEExLjUsMS41LDAsMCwxLDQwLjUsMzBoLTR2M2g0QTQuNTEsNC41MSwwLDAsMCw0NSwyOC41VjQuNUE0LjUxLDQuNTEsMCwwLDAsNDAuNSwwaC0yNEE0LjUxLDQuNTEsMCwwLDAsMTIsNC41WiIgZmlsbD0iI2QyZDJkMiIvPgogIDwvZz4KPC9zdmc+Cg==);
  background-color: #f1f2f4;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
}
